@font-face {
  font-family: "GothamRounded";
  src: url(./resources/fonts/GothamRounded-Medium.otf);
}

@font-face {
  font-family: "GothamRounded";
  src: url(./resources/fonts/GothamRounded-MediumItalic.otf);
  font-style: italic;
}

@font-face {
  font-family: "GothamRounded";
  src: url(./resources/fonts/GothamRounded-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: "GothamRounded";
  src: url(./resources/fonts/GothamRounded-BoldItalic.otf);
  font-weight: bold;
  font-style: italic;
}

html, body {
  min-width: 100%;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
}

a {
  text-decoration: none;
}

svg {
  overflow: hidden;
}

*.with-hover-overlay {
  position: relative;
}

*.with-hover-overlay::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  transition: all 0.1s;
  background-color: rgba(0,0,0,0);
}

button:focus, a:focus {
  outline: none;
}

*.with-hover-overlay:focus::after,
*.with-hover-overlay:hover::after,
*.with-hover-overlay.active:not(:hover)::after,
*.with-hover-overlay.active:not(:focus)::after {
  background-color: rgba(0,0,0,0.2);
}

*.with-hover-overlay:hover:disabled::after {
  background-color: rgba(0,0,0,0);
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}